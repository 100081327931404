"use client";
import classNames from "classnames";
import { HTMLAttributes, useEffect, useState } from "react";
import { CompanyPermissionType, UserType } from "../../../../utilities/redux/types";
import { parseDate } from "../../../../utilities/tools/date";
import { Tooltip } from "../../../components/infos";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { UserPicture } from "../../pictures";

interface Props extends HTMLAttributes<HTMLDivElement> {
  user: Partial<UserType>;
  backgroundColor?: CssVariablesType;
  pictureDisplay?: boolean;
  pictureSize?: number;
  padding?: number;
  fontSize?: number;
  fontWeight?: number;
  selected?: boolean;
  size?: "normal" | "small" | "big";
  companyPermission?: CompanyPermissionType;
  cb?: (user: UserType) => void;
}

export function UserBadge({
  user,
  className = "mt-0",
  backgroundColor = "gray1",
  color,
  pictureDisplay = true,
  pictureSize = 25,
  padding,
  fontSize,
  fontWeight,
  size = "normal",
  companyPermission,
  selected,
  cb,
  ...props
}: Props) {
  const [fontSizeComputed, setFontSizeComputed] = useState(fontSize);
  const [pictureSizeComputed, setPictureSizeComputed] = useState(pictureSize);
  const [paddingComputed, setPaddingComputed] = useState(padding);

  useEffect(() => {
    if (size === "normal") {
      setFontSizeComputed(fontSize ?? 0.9);
      setPictureSizeComputed(pictureSize ?? 25);
      setPaddingComputed(padding ?? 8);
    }
    if (size === "big") {
      setFontSizeComputed(fontSize ?? 1.2);
      setPictureSizeComputed(pictureSize ?? 30);
      setPaddingComputed(padding ?? 15);
    }
    if (size === "small") {
      setFontSizeComputed(fontSize ?? 0.75);
      setPictureSizeComputed(pictureSize ?? 22);
      setPaddingComputed(padding ?? 6);
    }
  }, [size, fontSize, pictureSize, padding]);

  return (
    <div
      onClick={() => cb && cb(user)}
      className={classNames("userBadge pr-2 mh-max o-hidden", className, { selected: selected }, { deleted: user?.deletedAt }, { clickable: cb })}
      style={{
        // maxWidth: "100%",
        padding: paddingComputed,
        paddingRight: paddingComputed && paddingComputed * 3,
        paddingLeft: (pictureDisplay && user?.email)
          ? paddingComputed
          : (paddingComputed && paddingComputed * 3),

        backgroundColor: `var(--color-${backgroundColor})`,
        flex: "0 1 auto",
        ...props?.style,
      }}
    >
      {pictureDisplay && user?.email && (
        <UserPicture
          user={user}
          size={pictureSizeComputed}
          companyPermission={companyPermission}
        />
      )}

      <Tooltip position="bottom" legend={user?.deletedAt ? `Utilisateur supprimé le ${parseDate(user?.deletedAt, true, true)}` : ""} className="m-0 o-hidden">
        <p
          className="ellipsis"
          style={{
            margin: "0px",
            fontSize: fontSizeComputed + "rem",
            fontWeight: fontWeight,

            color: selected ? "white" : "var(--color-gray15)",
          }}
        >
          {user && (user?.firstName || user?.lastName) ? user?.firstName + " " + user?.lastName : "Pas d'utilisateur renseigné"}
        </p>
      </Tooltip>

      {props?.children}
    </div>
  );
}
